import { DesktopTags } from "./DesktopTags";
import { MobileTags } from "./MobileTags";
import { useMediaQuery } from "~/hooks/useMediaQuery";

export const ProductTags = () => {
  const isAboveMobile = useMediaQuery("(min-width: 400px)");

  return (
    <div className="mb-10">
      {isAboveMobile ? <DesktopTags /> : <MobileTags />}
    </div>
  );
};
