import { TagButton } from "./TagButton";
import { Dialog } from "../Backroom/Dialog";
import { useState } from "react";
import { Text } from "../Text";
import { useActiveTag } from "../TagsMasonry/activeTagAtom";
import { TagButtonList } from "./TagButtonList";

type Props = {
  className?: string;
};

export const MobileTags = ({ className }: Props) => {
  const [open, setOpen] = useState(false);
  const { activeProductTag, activeSubCategory } = useActiveTag();

  const activeSubCategoryName = activeSubCategory
    ? `Alt i '${activeSubCategory.attributes.name}'`
    : undefined;

  return (
    <div className={className}>
      <Text className="text-xs mb-2">Filtrér produkter:</Text>
      <TagButton
        className="w-full"
        title={
          activeProductTag?.attributes.title ??
          activeSubCategoryName ??
          "Alle produkter"
        }
        isActive
        onClick={() => setOpen(true)}
      />

      <Dialog
        className="py-8 px-6 max-w-[800px] !h-auto top-[50%] translate-y-[-50%]"
        contentClassName="p-2"
        trigger={null}
        open={open}
        onOpenChange={setOpen}
      >
        <Text className="text-xs mb-4">Filtrér produkter:</Text>
        <TagButtonList
          onClick={(tag) => {
            if (tag.productTagId !== undefined) {
              setOpen(false);
            }
          }}
        />
      </Dialog>
    </div>
  );
};
