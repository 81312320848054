import classNames from "classnames";
import Image from "next/image";
import { useState } from "react";
import Icon from "~/assets/icons/Icon";
import Button from "~/components/Button";
import { StrapiImage, StrapiMedia, StrapiVideo } from "~/shared-types";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";
import { getMediaType } from "~/utils/getMediaType";
import { getVideoUrl } from "~/utils/getVideoUrl";

export function FeaturedMedia({
  media,
  className,
}: {
  media: StrapiMedia | null;
  className?: string;
}) {
  const mediaType = getMediaType(media);

  if (mediaType === "other") return null;

  const wrapperClassName = "-mx-[var(--page-x)] md:mx-0";
  const mediaClassName =
    "object-cover aspect-[2/1] md:rounded-xl w-full h-full";

  if (mediaType === "video") {
    return (
      <FeaturedVideo
        media={media as StrapiVideo}
        className={classNames("relative", wrapperClassName, className)}
        mediaClassName={mediaClassName}
      />
    );
  }

  return (
    <FeaturedImage
      media={media}
      className={classNames(wrapperClassName, className)}
      mediaClassName={mediaClassName}
    />
  );
}

export function FeaturedVideo({
  className,
  media,
  mediaClassName,
}: {
  className?: string;
  media: StrapiVideo | null;
  mediaClassName?: string;
}) {
  const [muted, setMuted] = useState(true);
  if (!media) return null;

  const { mime } = media.attributes;

  return (
    <div className={className}>
      <video playsInline autoPlay muted={muted} loop className={mediaClassName}>
        <source src={getVideoUrl(media)} type={mime} />
      </video>
      <Button
        className="absolute bottom-5 md:bottom-auto md:top-5 right-5 p-2 rounded-md bg-white hover:bg-neutral-100 text-secondary-600 hover:text-black"
        onClick={() => setMuted((prev) => !prev)}
        aria-label={muted ? "Unmute" : "Mute"}
      >
        {<Icon name={muted ? "volumeOff" : "volumeUp"} />}
      </Button>
    </div>
  );
}

export function FeaturedImage({
  media,
  className,
  mediaClassName,
}: {
  media: StrapiMedia | null;
  className?: string;
  mediaClassName?: string;
}) {
  return (
    <div className={className}>
      <Image
        {...getFormattedImageAttributes(media as StrapiImage, "full", {
          altFallback: media?.attributes.name,
        })}
        className={mediaClassName}
      />
    </div>
  );
}
