import classNames from "classnames";
import Image from "next/image";
import { StrapiStore } from "~/shared-types";
import { getFormattedImageAttributes } from "~/utils/getImageAttributes";

type StoreImageProps = {
  store: StrapiStore;
  className?: string;
};

export const StoreImage = ({ store, className }: StoreImageProps) => {
  if (!store?.attributes?.storeImage?.data) return null;

  return (
    <Image
      {...getFormattedImageAttributes(
        store?.attributes?.storeImage?.data,
        "small"
      )}
      className={classNames(
        className,
        "aspect-square object-cover rounded-full w-full",
        "max-w-[80px]"
      )}
    />
  );
};
