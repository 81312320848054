import classNames from "classnames";
import Button from "../Button";

type TagButtonProps = {
  className?: string;
  isActive?: boolean;
  onClick: () => void;
  title: string;
};

export const TagButton = ({
  className,
  title,
  isActive,
  onClick,
}: TagButtonProps) => {
  return (
    <Button
      onClick={onClick}
      variant={isActive ? "primary" : "basic"}
      className={classNames(
        className,
        "px-4 py-2 rounded-full text-sm font-bold"
      )}
    >
      {title}
    </Button>
  );
};

const getStaticTagClassNames = (isActive?: boolean) => {
  const sharedClassnames =
    "outline outline-[1px] outline-offset-1 outline-primary rounded-md";

  return isActive
    ? `${sharedClassnames} !border-primary !text-white`
    : `${sharedClassnames} !border-primary-300 !text-primary`;
};

export const StaticTagButton = (props: TagButtonProps) => (
  <TagButton
    {...props}
    className={classNames(
      getStaticTagClassNames(props.isActive),
      props.className
    )}
  />
);
