import classNames from "classnames";
import { Tag, useActiveTag } from "../TagsMasonry/activeTagAtom";
import { StaticTagButton, TagButton } from "./TagButton";

type Props = {
  onClick?: (tag: Tag) => void;
};

export const TagButtonList = ({ onClick }: Props) => {
  const {
    activeMainCategory,
    activeSubCategory,
    onSelectTag,
    mainCategoriesForProducts,
    subCategoriesForMainCategory,
    activeTag,
    productTagsForSubCategory,
  } = useActiveTag();

  return (
    <div>
      <h6 className="text-xs text-secondary-600">Hovedkategori</h6>
      <div className="flex mt-2 flex-row flex-wrap items-start gap-2">
        <StaticTagButton
          title="Alle produkter"
          isActive={!activeMainCategory?.id}
          onClick={() =>
            onSelectTag({
              mainCategoryId: null,
              subCategoryId: null,
              subCategoryName: null,
              productTagId: null,
            })
          }
        />
        {mainCategoriesForProducts.map(({ id, attributes }) => (
          <TagButton
            key={id}
            title={attributes.name}
            isActive={id === activeMainCategory?.id}
            onClick={() => onSelectTag({ mainCategoryId: id })}
          />
        ))}
      </div>
      <div className="pt-2 mt-5 border-t border-t-secondary-200">
        <h6 className="text-xs text-secondary-600">Underkategori</h6>
        <div className="flex mt-2 flex-row flex-wrap items-start gap-2">
          {!activeMainCategory?.id && !activeSubCategory?.id ? null : (
            <StaticTagButton
              className={classNames(activeSubCategory?.id && "!bg-primary-50")}
              title={
                activeMainCategory
                  ? `Alt i '${activeMainCategory.attributes.name}'`
                  : "Alle"
              }
              isActive={!activeSubCategory?.id}
              onClick={() =>
                onSelectTag({
                  subCategoryId: null,
                  subCategoryName: null,
                  productTagId: null,
                })
              }
            />
          )}
          {subCategoriesForMainCategory.map(({ id, attributes }) => (
            <TagButton
              key={id}
              title={attributes.name}
              isActive={
                id === activeSubCategory?.id ||
                attributes.name === activeSubCategory?.attributes.name
              }
              onClick={() =>
                onSelectTag({
                  subCategoryId: id,
                  subCategoryName: attributes.name,
                })
              }
            />
          ))}
        </div>
      </div>

      {productTagsForSubCategory.length === 0 ? null : (
        <div className="pt-2 mt-5 border-t border-t-secondary-200">
          <h6 className="text-xs text-secondary-600">Butikskategori</h6>
          <div className={"mt-2 flex gap-2 flex-wrap"}>
            {!activeSubCategory?.id && !activeTag?.productTagId ? null : (
              <StaticTagButton
                className={classNames(
                  activeTag?.productTagId && "!bg-primary-50"
                )}
                title={
                  activeSubCategory
                    ? `Alt i '${activeSubCategory.attributes.name}'`
                    : "Alle"
                }
                isActive={!activeTag?.productTagId}
                onClick={() => {
                  onSelectTag({ productTagId: null });
                  onClick?.({ productTagId: null });
                }}
              />
            )}

            {productTagsForSubCategory.map(({ id, attributes }) => (
              <TagButton
                key={id}
                title={attributes.title}
                isActive={id === activeTag?.productTagId}
                onClick={() => {
                  onSelectTag({ productTagId: id });
                  onClick?.({ productTagId: id });
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
