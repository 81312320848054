import classNames from "classnames";
import Icon from "~/assets/icons/Icon";
import { IconType } from "~/assets/icons/iconPaths";
import { InfoTidBit } from "~/pages/stores/InfoTidBit";
import { StrapiStore } from "~/shared-types";

type SocialLinkProps = {
  name: string;
  icon: IconType;
  link?: string;
};

const SocialLink = ({ name, icon, link }: SocialLinkProps) => {
  if (!link) return null;
  let validLink = link;

  try {
    new URL(link);
  } catch {
    validLink = "https://" + link;
  }

  return (
    <a
      className="transition-all text-secondary-600 hover:text-secondary-800"
      href={validLink}
      title={`Link til ${name}`}
      target="_blank"
    >
      <Icon className="w-6 h-6 pointer-events-none" name={icon} />
    </a>
  );
};

type Props = {
  store: StrapiStore;
  className?: string;
  linkContainerClassName?: string;
};

export const StoreSocialLinks = ({
  store,
  className,
  linkContainerClassName,
}: Props) => {
  const { facebookUrl, instagramUrl, websiteUrl } = store.attributes;

  if (!facebookUrl && !instagramUrl && !websiteUrl) return null;

  return (
    <InfoTidBit className={className}>
      <InfoTidBit.Header>Følg med her:</InfoTidBit.Header>
      <InfoTidBit.Body
        className={classNames("flex gap-2", linkContainerClassName)}
      >
        <SocialLink name="Hjemmeside" icon="website" link={websiteUrl} />
        <SocialLink name="Instagram" icon="instagram" link={instagramUrl} />
        <SocialLink name="Facebook" icon="facebook" link={facebookUrl} />
      </InfoTidBit.Body>
    </InfoTidBit>
  );
};
