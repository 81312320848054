import ReactMarkdown from "react-markdown";

import "./index.scss";
import classNames from "classnames";

type Props = {
  children?: string;
  className?: string;
};

export const MarkdownReader = ({ children, className }: Props) => (
  <ReactMarkdown skipHtml className={classNames("MarkdownReader", className)}>
    {children ?? ""}
  </ReactMarkdown>
);
