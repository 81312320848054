import { StrapiStore } from "~/shared-types";

export const getDeliveryDetails = (store?: StrapiStore | null) => {
  if (!store)
    return { offersFreeDelivery: false, freeDeliveryPurchasePrice: 0 };

  const { deliveryType, offersFreeDelivery, freeDeliveryPurchasePrice } =
    store.attributes;

  return {
    offersFreeDelivery: deliveryType !== "none" && offersFreeDelivery,
    freeDeliveryPurchasePrice,
  };
};
