import { StrapiProduct, StrapiProductTag } from "~/shared-types";
import { Tag } from "../TagsMasonry/activeTagAtom";

export const getProductTagsForActiveTag = (
  products: StrapiProduct[],
  activeTag?: Tag
) => {
  const tags = new Map<number, StrapiProductTag>();

  for (const product of products) {
    if (activeTag?.mainCategoryId) {
      const productHasMainCategory =
        product.attributes.subCategories?.data.some(
          (subCategory) =>
            subCategory.attributes.mainCategory?.data?.id ===
            activeTag.mainCategoryId
        );

      if (!productHasMainCategory) continue;
    }

    for (const subCategory of product.attributes.subCategories?.data ?? []) {
      if (
        activeTag?.subCategoryId &&
        subCategory.id !== activeTag.subCategoryId
      )
        continue;

      for (const productTag of product.attributes.productTags?.data ?? []) {
        tags.set(productTag.id, productTag);
      }
    }
  }

  return Array.from(tags.values());
};
