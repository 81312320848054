import classNames from "classnames";
import { ReactNode } from "react";
import { InfoTidBit } from "~/pages/stores/InfoTidBit";
import { StrapiStore } from "~/shared-types";

type StoreContactProps = {
  store: StrapiStore;
  className?: string;
  contactContainerClassName?: string;
};

function StoreContact({
  store,
  className,
  contactContainerClassName,
}: StoreContactProps) {
  const { publicEmail, publicPhone } = store.attributes;

  if (!publicEmail && !publicPhone) return null;

  return (
    <InfoTidBit className={className}>
      <InfoTidBit.Header>Kontakt:</InfoTidBit.Header>
      <InfoTidBit.Body>
        <address
          className={classNames(
            "not-italic flex flex-col",
            contactContainerClassName
          )}
        >
          <StoreContactLink className="mb-1" href={`mailto:${publicEmail}`}>
            {publicEmail}
          </StoreContactLink>
          <span>
            <StoreContactLink href={`tel:${publicPhone}`}>
              {publicPhone}
            </StoreContactLink>
          </span>
        </address>
      </InfoTidBit.Body>
    </InfoTidBit>
  );
}

function StoreContactLink({
  href,
  children,
  className,
}: {
  href: string;
  children: ReactNode;
  className?: string;
}) {
  return (
    <a
      className={classNames(
        "max-w-max text-secondary-800 hover:underline underline-offset-2 text-sm flex gap-3 items-center",
        className
      )}
      href={href}
    >
      {children}
    </a>
  );
}

export { StoreContact };
