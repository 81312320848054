import { StrapiStore } from "~/shared-types";
import { StoreImage } from "../products/[productId]/StoreImage";
import { StoreAddress } from "../products/[productId]/StoreAddress";
import { StoreContact } from "../products/[productId]/StoreContact";
import { StoreOpeningHours } from "../products/[productId]/StoreOpeningHours";
import { StoreSocialLinks } from "~/components/StoreSocialLinks";
import { StoreFreeDelivery } from "../products/[productId]/StoreFreeDelivery";
import { StoreOtherStores } from "../products/[productId]/StoreOtherStores";
import classNames from "classnames";

type Props = {
  store: StrapiStore;
  className?: string;
};

export const StoreInfoCard = ({ store, className }: Props) => {
  return (
    <div
      className={classNames(
        "bg-secondary-100 w-full min-w-[320px] max-w-[600px] rounded-lg p-10 lg:p-5 grid grid-cols-10 gap-5 sm:gap-8",
        className
      )}
    >
      <StoreImage className="mx-auto col-span-full" store={store} />

      <StoreAddress
        className="text-center md:text-left lg:text-center xl:text-left col-span-full md:col-span-6 lg:col-span-full xl:col-span-6"
        store={store}
      />

      <StoreOpeningHours
        className="text-center md:text-left lg:text-center xl:text-left items-center xl:items-start col-span-full md:col-span-4 lg:col-span-full xl:col-span-4"
        todayClassName="justify-center md:justify-start lg:justify-center xl:justify-start"
        store={store}
      />
      <StoreContact
        className="text-center md:text-left lg:text-center xl:text-left items-center col-span-full md:col-span-6 lg:col-span-full xl:col-span-6"
        contactContainerClassName="items-center md:items-start lg:items-center xl:items-start"
        store={store}
      />
      <StoreSocialLinks
        className="text-center md:text-left lg:text-center xl:text-left items-center xl:items-start col-span-full md:col-span-4 lg:col-span-full xl:col-span-4"
        linkContainerClassName="justify-center md:justify-start lg:justify-center xl:justify-start"
        store={store}
      />

      <StoreFreeDelivery
        className="mx-auto md:mx-[initial] lg:mx-auto xl:mx-[initial] col-span-full"
        store={store}
      />

      <StoreOtherStores
        className="text-center md:text-left lg:text-center xl:text-left col-span-full border-t border-secondary-200 pt-6"
        store={store}
      />
    </div>
  );
};
