import { StrapiStore } from "~/shared-types";
import classNames from "classnames";
import { TimeSpan } from "./TimeSpan";
import { useState } from "react";
import { DANISH_WEEKDAYS, WEEKDAYS, getToday } from "~/utils/openingHours";
import capitalize from "lodash.capitalize";
import { InfoTidBit } from "~/pages/stores/InfoTidBit";
import { RedLink } from "~/components/RedLink";
import { Dialog } from "~/components/Backroom/Dialog";

type StoreOpeningHoursProps = {
  store: StrapiStore;
  className?: string;
  todayClassName?: string;
};

const Description = ({
  description,
  className,
}: {
  description?: string;
  className?: string;
}) => {
  if (!description) return null;

  return (
    <p
      data-testid="description"
      className={classNames("text-xs text-secondary-700", className)}
    >
      {description}
    </p>
  );
};

function StoreOpeningHours({
  store,
  className,
  todayClassName,
}: StoreOpeningHoursProps) {
  const [open, setOpen] = useState(false);

  const openingHoursDescription = store.attributes.openingHoursDescription;

  const openHours = store.attributes.openingHours;

  if (!openHours && !openingHoursDescription) return null;

  const { today } = getToday(openHours);

  return (
    <>
      <InfoTidBit className={className}>
        <InfoTidBit.Header>Åbningstider:</InfoTidBit.Header>
        <InfoTidBit.Body>
          <Description className="mb-2" description={openingHoursDescription} />

          <TimeSpan
            className={classNames("flex flex-wrap gap-1", todayClassName)}
            title="I dag"
            openHours={today}
          />
          {Boolean(openHours) && (
            <RedLink
              className="inline-block mt-2"
              onClick={() => setOpen(true)}
            >
              Vis alle
            </RedLink>
          )}
        </InfoTidBit.Body>
      </InfoTidBit>
      <Dialog
        className="px-10 py-8"
        trigger={null}
        open={open}
        onOpenChange={setOpen}
      >
        <InfoTidBit>
          <InfoTidBit.Header>Åbningstider:</InfoTidBit.Header>
          <InfoTidBit.Body>
            <Description
              className="mb-2"
              description={openingHoursDescription}
            />

            <ul className="flex flex-col gap-4">
              {WEEKDAYS.map((weekday, i) => (
                <TimeSpan
                  key={weekday}
                  openHours={openHours?.[weekday]}
                  title={capitalize(DANISH_WEEKDAYS[i])}
                  className="grid grid-cols-7 gap-1"
                  titleClassName="col-span-full sm:col-span-1"
                  openHoursClassName="col-span-full sm:col-span-6"
                />
              ))}
            </ul>
          </InfoTidBit.Body>
        </InfoTidBit>
      </Dialog>
    </>
  );
}

export { StoreOpeningHours };
