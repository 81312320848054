import classNames from "classnames";
import { MarkdownReader } from "~/components/MarkdownReader";
import { ReadMore } from "~/components/ReadMore";
import { Text } from "~/components/Text";
import { StrapiStore } from "~/shared-types";

type StoreDescriptionProps = {
  store: StrapiStore;
  className?: string;
};

export const StoreDescription = ({
  store,
  className,
}: StoreDescriptionProps) => {
  return (
    <div className={classNames(className, "flex flex-col gap-5")}>
      <Text className="text-secondary-800 text-2xl sm:text-4xl">
        {store.attributes.introduction}
      </Text>
      <ReadMore>
        <MarkdownReader>{store.attributes.description}</MarkdownReader>
      </ReadMore>
    </div>
  );
};
