import { atom, useAtom } from "jotai";
import { useEffect, useMemo } from "react";
import {
  StrapiMainCategory,
  StrapiProduct,
  StrapiSubCategory,
} from "~/shared-types";
import { getIdsString } from "../ProductTags/getIdsString";
import { getProductTagsForActiveTag } from "../ProductTags/getProductTagsForActiveTag";
import { getSubCategoriesFromProducts } from "../ProductTags/getSubCategoriesFromProducts";

export type Tag = {
  mainCategoryId?: number | null;
  subCategoryId?: number | null;
  subCategoryName?: string | null;
  productTagId?: number | null;
};

export const activeTagAtom = atom<Tag | undefined>(undefined);
export const activeTagProductsAtom = atom<StrapiProduct[]>([]);

const doesProductHaveMainCategory = (
  product: StrapiProduct,
  mainCategoryId: number
) => {
  if (product.attributes.mainCategory?.data?.id === mainCategoryId) return true;

  const mainCategoriesFromSubCategories =
    product.attributes.subCategories?.data.flatMap(
      (sc) => sc.attributes.mainCategory?.data
    );

  return mainCategoriesFromSubCategories?.some(
    (mc) => mc?.id === mainCategoryId
  );
};

export const useActiveTag = (initialProducts?: StrapiProduct[]) => {
  const [activeTag, setActiveTag] = useAtom(activeTagAtom);
  const [allProducts, setAllProducts] = useAtom(activeTagProductsAtom);

  useEffect(() => {
    if (initialProducts) {
      setAllProducts(initialProducts);
      setActiveTag({
        subCategoryId: null,
        subCategoryName: null,
        productTagId: null,
      });
    }
  }, [getIdsString(initialProducts ?? [])]);

  const onSelectTag = (tag: Tag) => {
    if (tag.productTagId !== undefined) {
      setActiveTag((prev) => ({ ...prev, productTagId: tag.productTagId }));
    }

    if (tag.subCategoryId !== undefined) {
      setActiveTag((prev) => ({
        ...prev,
        subCategoryId: tag.subCategoryId,
        subCategoryName: tag.subCategoryName,
        productTagId: null,
      }));
    }

    if (tag.mainCategoryId !== undefined) {
      setActiveTag((prev) => ({
        ...prev,
        mainCategoryId: tag.mainCategoryId,
        subCategoryId: null,
        subCategoryName: null,
        productTagId: null,
      }));
    }
  };

  const productTagsForSubCategory = useMemo(() => {
    return getProductTagsForActiveTag(allProducts, activeTag);
  }, [
    activeTag?.subCategoryId,
    activeTag?.mainCategoryId,
    getIdsString(allProducts),
  ]);

  const productsForActiveTag = useMemo(() => {
    let relevantProducts = allProducts;

    if (activeTag?.mainCategoryId) {
      relevantProducts = allProducts.filter((product) => {
        return doesProductHaveMainCategory(
          product,
          activeTag.mainCategoryId as number
        );
      });
    }

    if (!activeTag?.subCategoryId && !activeTag?.productTagId)
      return relevantProducts;

    if (activeTag?.subCategoryId) {
      relevantProducts = (
        activeTag?.mainCategoryId ? relevantProducts : allProducts
      ).filter((product) => {
        if (activeTag.mainCategoryId) {
          return product.attributes.subCategories?.data.find(
            (sc) => sc.id === activeTag.subCategoryId
          );
        }

        return product.attributes.subCategories?.data.find(
          (sc) =>
            sc.id === activeTag.subCategoryId ||
            sc.attributes.name === activeTag.subCategoryName
        );
      });
    }

    if (!activeTag?.productTagId) return relevantProducts;

    return relevantProducts.filter((product) => {
      return product.attributes.productTags?.data?.find(
        (pt) => pt.id === activeTag.productTagId
      );
    });
  }, [JSON.stringify(activeTag), getIdsString(allProducts)]);

  const subCategoriesForProducts = useMemo(
    () => getSubCategoriesFromProducts(allProducts),
    [getIdsString(allProducts)]
  );

  const mainCategoriesForProducts = useMemo(() => {
    const subCategories = getSubCategoriesFromProducts(allProducts);
    const mainCategories = new Map<number, StrapiMainCategory>();
    subCategories.forEach((sc) => {
      const mainCategory = sc.attributes.mainCategory?.data;

      if (mainCategory) {
        mainCategories.set(mainCategory.id, mainCategory);
      }
    });

    return Array.from(mainCategories.values());
  }, [getIdsString(allProducts)]);

  const subCategoriesForMainCategory = useMemo(() => {
    if (activeTag?.mainCategoryId) {
      return subCategoriesForProducts.filter((sc) => {
        return (
          sc.attributes.mainCategory?.data?.id === activeTag.mainCategoryId
        );
      });
    }

    const subCategoriesByName = new Map<string, StrapiSubCategory>();

    subCategoriesForProducts.forEach((sc) => {
      subCategoriesByName.set(sc.attributes.name, sc);
    });

    return Array.from(subCategoriesByName.values());
  }, [activeTag?.mainCategoryId, getIdsString(subCategoriesForProducts)]);

  const activeMainCategory = useMemo(() => {
    if (!activeTag?.mainCategoryId) return undefined;

    return mainCategoriesForProducts.find(
      (mc) => mc.id === activeTag.mainCategoryId
    );
  }, [activeTag?.mainCategoryId, getIdsString(mainCategoriesForProducts)]);

  const activeSubCategory = useMemo(() => {
    if (!activeTag?.subCategoryId) return undefined;

    return subCategoriesForProducts.find(
      (sc) => sc.id === activeTag.subCategoryId
    );
  }, [activeTag?.subCategoryId, getIdsString(subCategoriesForProducts)]);

  const activeProductTag = productTagsForSubCategory.find(
    (pt) => pt.id === activeTag?.productTagId
  );

  return {
    activeTag,
    productTagsForSubCategory,
    subCategoriesForMainCategory,
    mainCategoriesForProducts,
    onSelectTag,
    productsForActiveTag,
    activeMainCategory,
    activeSubCategory,
    activeProductTag,
  };
};
