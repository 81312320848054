import classNames from "classnames";
import { PropsWithChildren } from "react";
import Button, { ButtonProps } from "../Button";

type RedLinkProps = ButtonProps &
  PropsWithChildren & {
    className?: string;
  };

export const RedLink = ({
  className,
  children,
  ...restProps
}: RedLinkProps) => {
  return (
    <Button
      {...restProps}
      variant="ghost"
      className={classNames(
        "underline text-primary hover:text-primary-600",
        className
      )}
    >
      {children}
    </Button>
  );
};
