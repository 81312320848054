import { StrapiStore } from "~/shared-types";
import { useState } from "react";
import { InfoTidBit } from "~/pages/stores/InfoTidBit";
import { Dialog } from "~/components/Backroom/Dialog";
import { RedLink } from "~/components/RedLink";
import { Text } from "~/components/Text";

type StoreOtherStoresProps = {
  store: StrapiStore;
  className?: string;
};

function StoreOtherStores({ store, className }: StoreOtherStoresProps) {
  const [open, setOpen] = useState(false);

  const otherStores = store.attributes.otherStores;
  const relevantOtherStores = otherStores?.filter(
    (store) => store.address && store.name
  );

  if (!relevantOtherStores || relevantOtherStores.length === 0) return null;

  const getGeoLink = (address: string) =>
    `https://maps.google.com/?q=${address}`;

  return (
    <>
      <InfoTidBit className={className}>
        <InfoTidBit.Header>Butikker:</InfoTidBit.Header>
        <InfoTidBit.Body>
          <p
            data-testid="description"
            className="text-xs text-secondary-700 mb-4"
          >
            Butikker hvor du kan købe varer fra {store.attributes.name}
          </p>
          <RedLink className="inline-block mt-2" onClick={() => setOpen(true)}>
            Vis butikker
          </RedLink>
        </InfoTidBit.Body>
      </InfoTidBit>
      <Dialog
        className="px-10 py-7"
        open={open}
        onOpenChange={setOpen}
        trigger={null}
      >
        <InfoTidBit>
          <InfoTidBit.Header>
            Butikker med produkter fra {store.attributes.name}
          </InfoTidBit.Header>
          <InfoTidBit.Body>
            <ul className="text-secondary-800 flex flex-col gap-4 pt-2">
              {relevantOtherStores.map((otherStore) => (
                <li key={otherStore.createdAt}>
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end flex-wrap gap-1">
                    <div>
                      <Text className="font-bold">{otherStore.name}</Text>
                      <Text>{otherStore.address}</Text>
                    </div>
                    <RedLink
                      target="_blank"
                      href={getGeoLink(otherStore.address)}
                    >
                      Find vej
                    </RedLink>
                  </div>
                </li>
              ))}
            </ul>
          </InfoTidBit.Body>
        </InfoTidBit>
      </Dialog>
    </>
  );
}

export { StoreOtherStores };
