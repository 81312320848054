import classNames from "classnames";
import Link from "next/link";
import { useRef } from "react";
import { useOnClickOutside, useToggle } from "usehooks-ts";
import Icon from "~/assets/icons/Icon";
import { getSideMenuItems } from "~/components/Backroom/FormEditStore/getSideMenuItems";
import Button from "~/components/Button";
import { getRoute } from "~/utils/getRoute";

type Props = {
  className?: string;
};

export const EditStoreButton = ({ className }: Props) => {
  const [showMenu, toggleMenu, setShowMenu] = useToggle(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(menuRef, () => setShowMenu(false));

  return (
    <div
      ref={menuRef}
      className={classNames(className, "flex items-center gap-5 relative")}
    >
      <Button
        variant="primary-light"
        size="lg"
        onClick={toggleMenu}
        className="flex items-center gap-2.5"
      >
        Rediger
        <Icon name="chevronDown" className="w-6 h-6" />
      </Button>
      {showMenu ? (
        <ul
          className={classNames(
            "absolute top-14 left-0 md:left-auto md:right-0 bg-white text-secondary-600 p-4 pb-1 rounded-lg shadow-sm z-10"
          )}
        >
          {getSideMenuItems().map((step) => (
            <Link
              key={step.payload.sideMenuTitle}
              href={getRoute(`/backroom/store/edit`, null, {
                query: { step: step.id },
              })}
              className="flex items-center gap-2.5 mb-2 p-2 hover:bg-secondary-100 hover:text-secondary-900  rounded-lg"
            >
              <Icon name={step.payload.icon} className="min-w-6 w-6 h-6" />
              {step.payload.sideMenuTitle}
            </Link>
          ))}
        </ul>
      ) : null}
    </div>
  );
};
