import classNames from "classnames";
import Icon from "~/assets/icons/Icon";
import { StrapiStore } from "~/shared-types";
import { getDeliveryDetails } from "~/utils/getDeliveryDetails";
import { stringToCurrency } from "~/utils/stringConversion";

type StoreFreeDeliveryProps = {
  store: StrapiStore;
  className?: string;
};

export const StoreFreeDelivery = ({
  store,
  className,
}: StoreFreeDeliveryProps) => {
  if (!store?.attributes?.offersFreeDelivery) return null;

  const { freeDeliveryPurchasePrice } = getDeliveryDetails(store);

  return (
    <div className={classNames("flex gap-2 items-center", className)}>
      <Icon name="shippingFront" className="w-5 h-5 text-secondary-600" />

      <p className="text-xs text-secondary-700 italic">
        Gratis levering ved køb over{" "}
        <span className="font-medium">
          {stringToCurrency({ amount: freeDeliveryPurchasePrice })}
        </span>
        .
      </p>
    </div>
  );
};
