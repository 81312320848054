import { StrapiProduct } from "~/shared-types";
import { ProductTags } from "../ProductTags";
import { ProductsMasonry } from "../ProductsMasonry";
import { useActiveTag } from "./activeTagAtom";

type Props = {
  products: StrapiProduct[];
  className?: string;
};

export const TagsMasonry = ({ products, className }: Props) => {
  const { productsForActiveTag } = useActiveTag(products);

  return (
    <div className={className}>
      <ProductTags />
      <ProductsMasonry products={productsForActiveTag} />
    </div>
  );
};
