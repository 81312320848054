import classNames from "classnames";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import debounce from "~/utils/debounce";
import { LinkButton } from "../LinkButton";

type ReadMoreProps = {
  className?: string;
  children: ReactNode;
};

function ReadMore({ className, children }: ReadMoreProps) {
  const [readMore, setReadMore] = useState({
    enabled: false,
    expanded: false,
  });

  const textContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // istanbul ignore next
    if (!textContainerRef?.current) return;

    function handleButtonVisibility() {
      const textContainer = textContainerRef.current;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const textContainerHeight = textContainer!.clientHeight;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const textContainerScrollHeight = textContainer!.scrollHeight;

      setReadMore((prev) => ({
        ...prev,
        enabled: textContainerHeight < textContainerScrollHeight,
      }));
    }

    handleButtonVisibility();
    const handleDebouncedResize = debounce(handleButtonVisibility, 100);

    addEventListener("resize", handleDebouncedResize);

    return () => {
      setReadMore({ enabled: false, expanded: false });
      removeEventListener("resize", handleDebouncedResize);
    };
  }, [textContainerRef]);

  return (
    <div className={className}>
      <div
        ref={textContainerRef}
        className={classNames([
          "mb-3 2xl:mb-6",
          !readMore.expanded && "line-clamp-4 md:line-clamp-8",
        ])}
      >
        {children}
      </div>
      {readMore.enabled && (
        <LinkButton
          theme="link"
          className="text-primary underline underline-offset-2"
          onClick={() =>
            setReadMore((prev) => ({ ...prev, expanded: !prev.expanded }))
          }
        >
          {readMore.expanded ? "Vis mindre" : "Læs mere"}
        </LinkButton>
      )}
    </div>
  );
}

export { ReadMore };
