import { RedLink } from "~/components/RedLink";
import { Text } from "~/components/Text";
import { InfoTidBit } from "~/pages/stores/InfoTidBit";
import { StrapiStore } from "~/shared-types";
import { getRoute } from "~/utils/getRoute";

type StoreAddressProps = {
  store: StrapiStore;
  className?: string;
};

function StoreAddress({ store, className }: StoreAddressProps) {
  if (!store.attributes.acceptsPickUp) return null;

  return (
    <InfoTidBit className={className}>
      <InfoTidBit.Header>Adresse:</InfoTidBit.Header>
      <InfoTidBit.Body>
        <address className={"not-italic flex flex-col"}>
          <Text>{store.attributes.shownAddress?.line1}</Text>
          <Text>
            {store.attributes.shownAddress?.postCode +
              " " +
              store.attributes.shownAddress?.city}
          </Text>
        </address>
        <RedLink
          className="inline-block mt-2"
          href={getRoute("/explore", {}, { query: { b: store.id } })}
        >
          Vis på Udforsk
        </RedLink>
      </InfoTidBit.Body>
    </InfoTidBit>
  );
}

export { StoreAddress };
