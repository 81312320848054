import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Text } from "~/components/Text";
import {
  CompoundComponentContext,
  useCompoundComponent,
} from "~/hooks/useCompoundComponent";

type Props = PropsWithChildren & {
  className?: string;
};

const InfoTidBit = ({ className, children }: Props) => {
  return (
    <div className={classNames(className)}>
      <CompoundComponentContext.Provider value={"InfoTidBit"}>
        {children}
      </CompoundComponentContext.Provider>
    </div>
  );
};

// InfoTidBit.Header
type HeaderProps = PropsWithChildren & {
  className?: string;
};

const Header = ({ children, className }: HeaderProps) => {
  useCompoundComponent("InfoTidBit", "Header");

  return (
    <Text className={classNames("text-xs mb-2 font-medium", className)}>
      {children}
    </Text>
  );
};

InfoTidBit.Header = Header;

// InfoTidBit.Body

type BodyProps = PropsWithChildren & {
  className?: string;
};

const Body = ({ children, className }: BodyProps) => {
  useCompoundComponent("InfoTidBit", "Body");

  return <div className={classNames("text-sm", className)}>{children}</div>;
};

InfoTidBit.Body = Body;

export { InfoTidBit };
