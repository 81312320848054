import { StrapiProduct, StrapiSubCategory } from "~/shared-types";

export const getSubCategoriesFromProducts = (products: StrapiProduct[]) => {
  const uniqueSubCategories = new Map<number, StrapiSubCategory>();

  for (const product of products) {
    for (const subCategory of product.attributes.subCategories?.data ?? []) {
      uniqueSubCategories.set(subCategory.id, subCategory);
    }
  }

  return Array.from(uniqueSubCategories.values());
};
