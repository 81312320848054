import React from "react";
import { formatHours, getSortedHours } from "~/utils/openingHours";
import { OpeningHourObject } from "~/shared-types";
import classNames from "classnames";

export type ScheduleProps = {
  openHours?: OpeningHourObject;
  title: string;
  className?: string;
  titleClassName?: string;
  openHoursClassName?: string;
};

export const TimeSpan = ({
  openHours,
  title,
  className,
  titleClassName,
  openHoursClassName,
}: ScheduleProps) => {
  const { hours, type } = openHours ?? {};
  const inputType = !hours || hours.length === 0 ? "closed" : type;

  return (
    <li className={classNames("list-none text-secondary-800", className)}>
      <span className={classNames("block", titleClassName)}>{title}:</span>

      <span className={classNames(openHoursClassName)}>
        {inputType === "closed" || !hours || hours.length === 0 ? (
          <span>Lukket</span>
        ) : (
          <>
            {inputType === "open" && (
              <span className="w-36">{formatHours(getSortedHours(hours))}</span>
            )}
            {inputType === "openByAgreement" && (
              <span>
                Åben efter aftale: {formatHours(getSortedHours(hours))}
              </span>
            )}
          </>
        )}
      </span>
    </li>
  );
};
