import classNames from "classnames";
import Link from "next/link";
import Icon from "~/assets/icons/Icon";
import { useSingleStoreAtom } from "~/atoms/singleStore";
import { ReturnedRoute } from "~/utils/getRoute";

type BreadcrumbsProps = {
  links: Array<{
    href?: ReturnedRoute;
    label: string;
  }>;
  className?: string;
};

export const Breadcrumbs = ({ links, className }: BreadcrumbsProps) => {
  const { isLimited } = useSingleStoreAtom();

  if (links.length === 0 || isLimited) return null;

  return (
    <nav className={classNames("flex", className)} aria-label="Breadcrumb">
      <ol className="inline-flex items-center text-xs md:text-sm text-secondary-600 flex-wrap">
        {links.map((link, index) => {
          const isFirst = index === 0;
          const isCurrentPage = index === links.length - 1;

          return (
            <li
              key={link.href + link.label}
              aria-current={isCurrentPage ? "page" : undefined}
              className="inline-flex items-center"
            >
              {!isFirst && (
                <Icon name="chevronRight" className="mx-1 w-4 h-4" />
              )}
              {isCurrentPage || !link.href ? (
                <div
                  className={classNames(
                    "inline-flex items-center font-medium hover:text-secondary-800"
                  )}
                >
                  {link.label}
                </div>
              ) : (
                <Link
                  href={link.href}
                  className={classNames(
                    "inline-flex items-center font-medium hover:text-secondary-800 underline"
                  )}
                >
                  {link.label}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
